import * as React from 'react';
import './mobile-menu.scss';
import { Link } from 'gatsby-plugin-react-i18next';
import { careerUrl, talentsUrl } from '../navbar';

const MobileMenu = (props: any) => {
  const { t } = props.i18n;
  const currentPage = (href: string) => {
    if (typeof window !== 'undefined' && href === window.location.pathname) {
      return 'mobile-menu_current-page';
    }
  };
  return (
    <main className="mobile-menu">
      {/*<div className="mobile-menu_content">*/}
      <div className="mobile-menu_option">
        <Link
          to={'/'}
          onClick={props.closeMenu}
          className={currentPage('/')}>
          {t('navigation.aboutUs')}
        </Link>
      </div>
      <div className="mobile-menu_option">
        <Link
          to={'/how-we-work'}
          onClick={props.closeMenu}
          className={currentPage('/how-we-work')}>
          {t('navigation.howWeWork')}
        </Link>
      </div>
      <div className="mobile-menu_option">
        <Link
          to={careerUrl}
          onClick={props.closeMenu}
          className={currentPage('/career')}>
          {t('navigation.career')}
        </Link>
      </div>
      <div className="mobile-menu_option">
        <Link
          to={'/blog'}
          onClick={props.closeMenu}
          className={currentPage('/blog')}>
          {t('navigation.blog')}
        </Link>
      </div>
      <div className="mobile-menu_option">
        <Link
          to={'/#contact'}
          onClick={props.closeMenu}
          className={currentPage('/#contact')}>
          {t('navigation.contact')}
        </Link>
        {/*</div>*/}
      </div>
      <div className="mobile-menu_option">
        <Link
          to={talentsUrl}
          onClick={props.closeMenu}
          className={currentPage('/talents')}>
          {t('navigation.talents')}
        </Link>
      </div>
    </main>
  );
};

export default MobileMenu;
